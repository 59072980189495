<AppSnackBar
  :message="notifyMessage"
  :notificationType="notifyType"
  v-if="isNotify"
></AppSnackBar>
<modal
  :title="title"
  :handler="visibleModal"
  :isLoading="isLoading"
  :cssClass="'modal-xl-1000 max-height-100-145 content-overflow-hidden'"
  @modalHandler="modalHandler"
>
  <template v-slot:content>
    <app-loader v-if="isLoading || isColumnLoading || loader"></app-loader>
    <div class="min-h-280 border-top-normal-1 pt-16">
      <Grid
        v-if="listColumns.length"
        ref="grid"
        :class="'max-height-100-251 first-col-checkbox-center table-design-2 th-td-checkbox-style-2 mb-60 add-audience-from-digital-pass-table'"
        :data-items="allAudienceData.items"
        :take="take"
        :skip="skip"
        :total="total"
        :pageable="gridPageable"
        :selectable="true"
        :selected-field="selectedField"
        :columns="columns"
        @selectionchange="onSelectionChange"
        @datastatechange="dataStateChange"
        @headerselectionchange="onHeaderSelectionChange"
        :sortable="true"
        :sort="sort"
        @sortchange="sortChangeHandler"
      >
        <toolbar>
          <div class="d-flex justify-content-between w-100 align-items-center">
            <!-- <checkbox :default-checked="false" :label="' Select All'" /> // will work  -->

            <div class="search-box-wrap width-220">
              <span class="k-icon k-i-zoom"></span>
              <span class="k-textbox k-grid-search k-display-flex">
                <k-input
                  :class="'mb-0 width-220'"
                  placeholder="Search"
                  :value="searchWord"
                  @input="onSearch"
                >
                </k-input>
              </span>
            </div>
            <div>
              <checkbox
                v-if="allAudienceData?.items?.length"
                v-model="forceSelectAll"
                :default-checked="false"
                :label="selectAllLabelGenerator()"
              />
            </div>
          </div>
        </toolbar>
        <template v-slot:contactTemplate="{ props }">
          <td>
            <contact
              v-if="props.rowType !== 'groupHeader'"
              :data-item="props.dataItem"
            ></contact>
          </td>
        </template>
        <template v-slot:nameCell="{ props }">
          <td>
            <span class="font-14 font-w-500 color-blue"
              >{{props.dataItem.name}}</span
            >
          </td>
        </template>
        <template v-slot:subscriberStatusCell="{props}">
          <td>
            <div class="d-flex justify-content-between">
              <span
                v-if="props.dataItem.subscriberStatus==0"
                class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8"
                >Non-subscribed</span
              >
              <span
                v-if="props.dataItem.subscriberStatus==1"
                class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 live"
                >Subscribed</span
              >
              <span
                v-if="props.dataItem.subscriberStatus==2"
                class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 draft"
                >Unsubscribed</span
              >
              <span
                v-if="props.dataItem.subscriberStatus==3"
                class="campaign-status styled-2 font-12 font-w-600 radius-100 px-8 paused"
                >Cleaned</span
              >
            </div>
          </td>
        </template>
        <template
          v-for="(item,index) in dateCells"
          :key="index"
          v-slot:[item.cell]="{ props }"
        >
          <td>
            <span> {{this.$globalDateFormatter(props.dataItem[item.field])}} </span>
          </td>
        </template>
        <GridNoRecords> No audiences found. </GridNoRecords>
      </Grid>
    </div>
  </template>
  <template v-slot:footer>
    <div class="d-flex justify-content-end">
      <div class="me-1">
        <kbutton
          @click="modalHandler"
          :fill-mode="'outline'"
          :theme-color="'primary'"
          :class="'flex-none border-normal-1 text-color bg-white hvr-bg-primary'"
          >Cancel</kbutton
        >
      </div>
      <div class="ms-1">
        <kbutton
          v-if="forceSelectAll"
          :theme-color="'primary'"
          :class="'flex-none'"
          @click="addAudience"
        >
          Add Audience
        </kbutton>
        <kbutton
          v-else
          :disabled="!selectedItems.length"
          :theme-color="'primary'"
          :class="'flex-none'"
          @click="addAudience"
        >
          Add
        </kbutton>
      </div>
    </div>
  </template>
</modal>
